.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  background-color: #fffbf3;
  color: #333;
  transform: translateY(0);
  transition: transform 0.3s ease;
  transition-property: height, background-color, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  z-index: 1000;
  border-radius: 0px 0px 30px 30px;

  .logo {
    position: fixed;
    height: 80px;
    top: 30px;
    left: 120px;
    transition-property: top, height;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    border-radius: 0px;
  }
  .links {
    position: fixed;
    right: 120px;
    top: 40px;
    transition-property: top;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }
  .headerUl {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
    overflow: hidden;
    // background-color: #333;
  }
  .link {
    width: 150px;
  }

  li {
    float: left;
    display: block;
    // color: black;
    text-align: center;
    padding: 14px 5px;
    font-size: 14pt;
    margin-bottom: 0pt;
    line-height: 1.2;
    a {
      color: black;
      //   display: block;
      //   color: white;
      //   text-align: center;
      //   padding: 14px 16px;
      text-decoration: none;
      transition-property: color;
      transition-duration: 0.3s;
      transition-timing-function: ease;
    }
  }

  /* Change the link color to #111 (black) on hover */
  li a:hover {
    color: #4d4b49;
  }
  .language {
    display: inline-block;
    color: black;
    text-align: center;
    .flage {
      width: 20.5px;
      height: 20.5px;
      display: block;
      float: left;
    }
    .country {
      padding-left: 5px;
      // width: 100px;
      display: block;
      float: left;
      transition-property: color;
      transition-duration: 0.3s;
      transition-timing-function: ease;
    }
    :hover {
      color: #4d4b49;
    }
  }
  // #emoji {
  //   width: 20px;
  // }
}

.small {
    .logo {
      top: 10px;
      height: 40px;
      border-radius: 0px;
  }
  .header {
    box-shadow: 0 9px 9px -9px rgba(0, 0, 0, 0.13);
    height: 60px; //506763
    background-color: #506763;
    // background-color: #d8efd3;
    color: #ffffff;
    // color: #000;
  }
  .links {
    top: 5px;
  }
  li a {
    color: #ffffff;
  }
  li a:hover {
    color: #d0d0d0;
  }
  .language {
    color: #ffffff;
    :hover {
      color: #d0d0d0;
    }
  }
}

@media (max-width: 600px) {
  .header {
    width: 100%;
    li {
      background-color: #506763;
      a {color:white}
    }
  }
  
}

.hidden {
  // top: 400px;
  transform: translateY(-110%);
}
