/* p {
  width: 800px;
} */
html,
body {
  overflow-x: hidden;
}
body {
  width: 100%;
}

.App {
  position: absolute;
  top: 120px;
  // top: 400px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 5px;
  width: 100%;
  text-align: center;
  background-color: #506763;
  z-index: 5;
}
