html {
  height: 100%;
}

// :root {
//   --primary: #4628ff;
//   --warning: #ffd028;
//   --danger: #ed3f27;
//   --success: #75fa83;
//   --white: #fdfdfd;
//   --dark: #181717;
// }

body {
  margin: 0;
  font-family: "Source Sans Pro", source-sans-pro, sans-serif;
  // font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  // monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  background-color: #fffbf3;
  height: 100%;
}

// img {
//   height: 500px;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Amaranth", sans-serif;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 1.5pt;
  padding: 0px;
  margin: 0px;
  font-size: 30pt;
}

p {
  // font-size: 20px;
  font-size: 14pt;
  letter-spacing: 0.025em;
  line-height: 20pt;
  margin-bottom: 10pt;
  font-weight: 400;
  word-wrap: break-word;
  // column-count: 2;
  // column-gap: 40px;
  // column-width: 400px;
}

li {
  font-size: 14pt;
  letter-spacing: 0.025em;
  line-height: 20pt;
  margin-bottom: 10pt;
  font-weight: 400;
  word-wrap: break-word;
}
a {
  font-size: 14pt;
  letter-spacing: 0.025em;
  line-height: 20pt;
  // color: #000;
}

.column {
  column-count: 2;
  column-width: 280px;
}

img {
  border-radius: 20px;
}
