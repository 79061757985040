.subMenu {
  margin-top: 5px;
}
.menuBox {
  background-color: #000000;
  margin-top: -5px;
  margin-left: -10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 160px;
  border-radius: 5px;
  color: #ffffff;
  padding-left: 0px;

  .cont {
    cursor: pointer;
    display: inline-block;
    // color: black;
    text-align: center;
  }

  .flage {
    width: 20.5px;
    height: 20.5px;
    display: block;
    float: left;
  }
  .subCountry {
    padding-left: 5px;
    // width: 100px;
    display: block;
    float: left;
    // color: #ffffff;
  }

  li {
    padding-left: 10px !important;
    width: 140px;
  }
  li a {
    color: #ffffff !important;
  }
  :hover {
    color: #d0d0d0 !important;
  }
}
